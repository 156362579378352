.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #ffc542;
}
.lateral-menu {
  padding: 20px;
  width: 250px;
  height: 100%;
  background-color: rgba(223, 168, 38, 0.85);
  display: block;
  position: absolute;
  z-index: 1001;
  right: 0;
  transition: all 1s linear;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.bottom-row {
  position: absolute;
  bottom: 0;
  width: 85%;
  margin-bottom: 15px;
}
.actions-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
